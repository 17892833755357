import {Injectable} from '@angular/core';

@Injectable()
export class ParserHelperService {

    /*

    public static getSceneNames(g: Element): ISceneText[] {
        let names = [];
        let texts = g.querySelectorAll('#scene-name');
        let tl = texts.length;
        for (let i = 0; i < tl; i++) {
            let n = texts[i] as Element;
            // .querySelector('text');
            let ts = n.querySelector('tspan');
            let nameItem = {
                fontSize: parseFloat(n.closest('#scene-name').getAttribute('font-size')),
                lineSpacing: n.hasAttribute('line-spacing') ? parseFloat(n.getAttribute('line-spacing')) : '',
                letterSpacing: n.hasAttribute('letter-spacing') ? parseFloat(n.getAttribute('letter-spacing')) : '',
                text: ts.textContent,
                textX: parseFloat(ts.getAttribute('x')),
                textY: parseFloat(ts.getAttribute('y')),
                fontWeight: n.hasAttribute('font-weight') ? n.getAttribute('font-weight') : '400',
                fill: n.hasAttribute('fill') ? n.getAttribute('fill') : null,
            };
            names.push(nameItem);
        }
        return names;
    }
*/

    // public static getSchemeNames(g: Element): ISectorNameText[] {
    public static getSchemeNames(g: Element): ISceneText[] {
        const names = [];
        const texts = g.querySelectorAll('* > text');
        const tl = texts.length;
        for (let i = 0; i < tl; i++) {
            const n = texts[i] as Element;
            const ts = n.querySelector('tspan');
            let fz = 18;
            if (n.hasAttribute('font-size')) {
                fz = parseFloat(n.getAttribute('font-size'));
            } else if (g.hasAttribute('font-size')) {
                fz = parseFloat(g.getAttribute('font-size'));
            }

            const nameItem = {
                fontSize: fz,
                text: ts.textContent,
                textX: parseFloat(ts.getAttribute('x')),
                textY: parseFloat(ts.getAttribute('y')),
                fontWeight: n.hasAttribute('font-weight') ? n.getAttribute('font-weight') : '400',
                fill: n.hasAttribute('fill') ? n.getAttribute('fill') : null
            };
            if (nameItem.fill === null) {
                nameItem.fill = g.hasAttribute('fill') ? g.getAttribute('fill') : null;
            }
            names.push(nameItem);
        }
        return names.length ? names : null;
    }

    public static getShapeLangNames(g: Element): IMultiLang {
        const nA = g.querySelectorAll('#lang > g');
        const box = g.querySelector('#lang');
        const nAl = nA.length;
        const boxPos = ParserHelperService.getPosition(box.hasAttribute('transform') ? box.getAttribute('transform') : '');
        const boxFz = box.hasAttribute('font-size') ? box.getAttribute('font-size') : null;
        // console.log(boxFz);
        const boxFw = box.hasAttribute('font-weight') ? box.getAttribute('font-weight') : null;
        const boxFill = box.hasAttribute('fill') ? box.getAttribute('fill') : null;
        let data: IMultiLang = {};
        for (let i = 0; i < nAl; i++) {
            const item = nA[i] as Element;
            const transform = item.hasAttribute('transform') ? item.getAttribute('transform') : '';
            let pos = ParserHelperService.getPosition(transform);
            pos[0] += boxPos[0];
            pos[1] += boxPos[1];
            let fz = item.hasAttribute('font-size') ? item.getAttribute('font-size') : null;
            if ( boxFz && !fz) {
                fz = boxFz;
            }
            let fw = item.hasAttribute('font-weight') ? item.getAttribute('font-weight') : null;
            if ( boxFw && !fw ) {
                fw = boxFw;
            }
            let fill = item.hasAttribute('fill') ? item.getAttribute('fill') : null;
            if ( boxFill && !fill) {
                fill = boxFill;
            }
            const code = item.getAttribute('id') === 'ua' ? 'uk' : item.getAttribute('id');
            data[code] = ParserHelperService.getShapeNames(item, pos, fz, fw, fill);
        }
        if (nAl < 1) {
            data = null;
        }
        return data;
    }

    public static getShapeNames(g: Element, offset?: number[], fontSize?: string, fontWeight?: string, fill?: string): ISceneText[] {
        let names = [];
        let texts = g.querySelectorAll('* > text');
        let tl = texts.length;
        let gd: IFontData = {};

        if ( !offset ) {
            offset = [0, 0];
        }

        let fz = fontSize ? parseFloat(fontSize) : 18;
        if (g.hasAttribute('font-size')) {
            fz = parseFloat(g.getAttribute('font-size'));
        }
        if ( fontWeight ) {
            gd.fontWeight = fontWeight;
        }
        if (g.hasAttribute('font-weight')) {
            gd.fontWeight = g.getAttribute('font-weight');
        }
        if (fill) {
            gd.fill = fill;
        }
        if (g.hasAttribute('fill')) {
            gd.fill = g.getAttribute('fill');
        }
        gd.x = offset[0];
        gd.y = offset[1];

        for (let i = 0; i < tl; i++) {
            let n = texts[i] as Element;
            let ts = n.querySelector('tspan');

            if (n.hasAttribute('font-size')) {
                fz = parseFloat(n.getAttribute('font-size'));
            }

            let nameItem = {
                fontSize: fz,
                text: ts.textContent,
                textX: parseFloat(ts.getAttribute('x')) + gd.x,
                textY: parseFloat(ts.getAttribute('y')) + gd.y,
                fontWeight: n.hasAttribute('font-weight') ? n.getAttribute('font-weight') : '400',
                fill: n.hasAttribute('fill') ? n.getAttribute('fill') : null
            };

            if (gd.fontWeight && (nameItem.fontWeight === '400')) {
                nameItem.fontWeight = gd.fontWeight;
            }
            if (gd.fill && !nameItem.fill) {
                nameItem.fill = gd.fill;
            }

            if (!nameItem.fill) {
                nameItem.fill = '#232323';
            }

            names.push(nameItem);
        }
        return names.length ? names : null;
    }

    public static checkRowTable(rows: ISchemeRow[]): boolean {
        let xr = rows.filter((el: ISchemeRow) => el.tables !== null);
        return !!xr.length;
    }


    public static getSectorCountCell(sector: Element): number {
        let count: number = 0;

        let rowSet = sector.querySelectorAll('#set');

        for (let i = 0; i < rowSet.length; i++) {
            const r = rowSet[i] as Element;
            count += r.querySelectorAll('* > g').length;
        }

        return count;

    }


    public static getSvgTag(el: Element): ISectorPath {
        let elem: ISectorPath = {
            tag: el.nodeName,
            transform: el.hasAttribute('transform') ? el.getAttribute('transform') : '',
            fill: el.hasAttribute('fill') ? el.getAttribute('fill') : '',
        };
        if (el.hasAttribute('fill-opacity')) {
            elem.fillOpacity = parseFloat(el.getAttribute('fill-opacity'));
        }
        if (el.hasAttribute('stroke-width')) {
            elem.strokeWidth = parseFloat(el.getAttribute('stroke-width'));
        }
        if (el.hasAttribute('d')) {
            elem.d = el.getAttribute('d');
        }
        if (el.hasAttribute('points')) {
            elem.points = el.getAttribute('points');
        }
        if (el.hasAttribute('x')) {
            elem.x = parseFloat(el.getAttribute('x'));
        }
        if (el.hasAttribute('y')) {
            elem.y = parseFloat(el.getAttribute('y'));
        }
        if (el.hasAttribute('width')) {
            elem.w = parseFloat(el.getAttribute('width'));
        }
        if (el.hasAttribute('height')) {
            elem.h = parseFloat(el.getAttribute('height'));
        }
        if (el.hasAttribute('rx')) {
            elem.rx = parseFloat(el.getAttribute('rx'));
        }
        if (el.hasAttribute('cx')) {
            elem.cx = parseFloat(el.getAttribute('cx'));
        }
        if (el.hasAttribute('cy')) {
            elem.cy = parseFloat(el.getAttribute('cy'));
        }
        if (el.hasAttribute('r')) {
            elem.cr = parseFloat(el.getAttribute('r'));
        }
        if (el.hasAttribute('stroke')) {
            elem.stroke = el.getAttribute('stroke');
        }
        if (el.hasAttribute('opacity')) {
            elem.opacity = el.getAttribute('opacity');
        }

        return elem;
        // return {
        //   tag: el.nodeName,
        //   // transform: el.hasAttribute('transform') ? el.getAttribute('transform') : '',
        //   // fill: el.hasAttribute('fill') ? el.getAttribute('fill') : '',
        //   // fillOpacity: el.hasAttribute('fill-opacity') ? parseFloat(el.getAttribute('fill-opacity')) : 1,
        //   // strokeWidth: el.hasAttribute('stroke-width') ? parseFloat(el.getAttribute('stroke-width')) : 0,
        //   // d: el.hasAttribute('d') ? el.getAttribute('d') : '',
        //   // points: el.hasAttribute('points') ? el.getAttribute('points') : '',
        //   // x: el.hasAttribute('x') ? parseFloat(el.getAttribute('x')) : 0,
        //   // y: el.hasAttribute('y') ? parseFloat(el.getAttribute('y')) : 0,
        //   // w: el.hasAttribute('width') ? parseFloat(el.getAttribute('width')) : 0,
        //   // h: el.hasAttribute('height') ? parseFloat(el.getAttribute('height')) : 0,
        //   // rx: el.hasAttribute('rx') ? parseFloat(el.getAttribute('rx')) : 0,
        //   // cx: el.hasAttribute('cx') ? parseFloat(el.getAttribute('cx')) : 0,
        //   // cy: el.hasAttribute('cy') ? parseFloat(el.getAttribute('cy')) : 0,
        //   cr: el.hasAttribute('r') ? parseFloat(el.getAttribute('r')) : 0,
        //   stroke: el.hasAttribute('stroke') ? el.getAttribute('stroke') : '',
        //   opacity: el.hasAttribute('opacity') ? el.getAttribute('opacity') : '1',
        // };
    }


    public static getFigurePath(group: Element): ISectorPath[] {
        let scenePathArr = [];
        let sceneElArr = group.querySelectorAll('path, rect, polygon');
        let sceneElArrSize = sceneElArr.length;
        for (let i = 0; i < sceneElArrSize; i++) {
            const item = sceneElArr[i] as Element;
            scenePathArr.push(ParserHelperService.getSvgTag(item));
        }
        return scenePathArr;
    }


    public static getPosition(str: string): number[] {
        if (str === '') {
            return [0, 0];
        } else {
            let pos = str.replace('translate(', '').replace(')', '').split(', ');
            let x = parseFloat(parseFloat(pos[0]).toFixed(3));
            let y = parseFloat(parseFloat(pos[1]).toFixed(3));
            return [x, y];
        }
    }


    public static getBeautyRowOption(item: Element): ISectorRowNameOption {
        return {
            fontSize: parseFloat(item.getAttribute('font-size')),
            fontWeight: item.hasAttribute('font-weight') ? item.getAttribute('font-weight') : '400',
            fill: item.hasAttribute('fill') ? item.getAttribute('fill') : '#000'
        };
    }


    public static getBeautyRowList(arr: any, position: number[]): ISectorRowNameData[] {
        let list: ISectorRowNameData[] = [];
        let xy = position[0];
        let yx = position[1];

        for (let i = 0; i < arr.length; i++) {
            let t = arr[i].querySelector('tspan');
            let r = {
                text: t.textContent,
                x: parseFloat(t.getAttribute('x')) + xy,
                y: parseFloat(t.getAttribute('y')) + yx
            };
            list.push(r);
        }
        return list;
    }

    public static getFullZonePosition(sp: number[], zp: number[]): number[] {
        let newPosition: number[] = [0, 0];
        newPosition[0] = sp[0] + zp[0];
        newPosition[1] = sp[1] + zp[1];
        return newPosition;
    }


    public static getFullRowPosition(sp: number[], zp: number[], rp: number[]): number[] {
        let newPosition: number[] = [0, 0];
        newPosition[0] = sp[0] + zp[0] + rp[0];
        newPosition[1] = sp[1] + zp[1] + rp[1];
        return newPosition;
    }


    public static addSetMarginToCell(rowSetPos: number[], cellPos: number[]): number[] {
        return [rowSetPos[0] + cellPos[0], rowSetPos[1] + cellPos[1]];
    }


    public static getFullPointPosition(sp: number[], zp: number[], rp: number[], cp: number[]): number[] {
        let newPosition: number[] = [0, 0];
        newPosition[0] = sp[0] + zp[0] + rp[0] + cp[0];
        newPosition[1] = sp[1] + zp[1] + rp[1] + cp[1];
        return newPosition;
    }


    public static getBeautyRowName(g: Element): ISectorRowName {
        let rowArr = g.querySelectorAll('text');
        let position: number[] = g.hasAttribute('transform') ? ParserHelperService.getPosition(g.getAttribute('transform')) : [0, 0];

        return {
            option: ParserHelperService.getBeautyRowOption(g),
            data: ParserHelperService.getBeautyRowList(rowArr, position)
        };
    }

    public static getFilterPoint(g: Element): string {
        const circle = g.querySelector('circle');
        return circle ? circle.getAttribute('r') : '0';
    }

    public static createSchemeDesc(sector: Element): ISchemeLang {
        let data: ISchemeLang = {};
        let texts = sector.querySelectorAll('#sector-desc > text');
        let tl = texts.length;

        for (let i = 0; i < tl; i++) {
            const item = texts[i] as Element;
            const code = item.getAttribute('id') === 'ua' ? 'uk' : item.getAttribute('id');
            data[code] = item.querySelector('tspan').textContent;
        }

        if ( tl < 1 ) {
            data = null;
        }

        return data;

    }

}
