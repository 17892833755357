import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Base64 } from 'js-base64';
import { ParserService } from './services/parser.service';
import { ParserHelperService } from './services/parser-helper.service';

// tslint:disable max-file-line-count

@Component({
  selector: 'app-parser',
  templateUrl: './parser.component.html',
  styleUrls: ['./parser.component.css']
})
export class ParserComponent implements OnInit {

  @ViewChild('svgLoadInput') public svgLoadInput: ElementRef;
  @ViewChild('svgDefs') public svgDefs: ElementRef;
  @ViewChild('svgPoints') public svgPoints: ElementRef;
  public loggerBox: Element;

  public openSchemeInfo: boolean = false;
  public schemeInfo: ISchemeInfo[] = null;
  public schemeFullInfo: number[];

  public toggleView: FormGroup;

  public svgHtmlRes: SafeHtml;
  public fileLink: SafeResourceUrl;

  public scheme: any;
  public fileName: string;
  public toggleViewVar: string[] = [
    'point', 'row', 'zone', 'sector',
    'point_row', 'point_zone', 'point_sector',
    'point_row_zone', 'point_row_sector'
  ];
  public checkViewVar: string[] = [
    'sector_title', 'row_title', 'posters', 'shapes', 'text', 'table', 'cell', 'cinema'
  ];
  public countLogError: number = 0;
  public logEvent: ILogging[] = [];
  public showLogs: string = 'all';

  public fileLoadAccess: boolean = false;
  public showLoader: boolean = false;
  public csvLoadAccess: boolean = false;
  public hasRowTables: boolean = false;
  public lang: string = 'uk';
  public langNav: ILangNav[] = [];

  public constructor(
    private _sanitizer: DomSanitizer,
    private _parserService: ParserService
  ) {}

  public ngOnInit(): void {

    this._parserService.logging$
      .subscribe((data: ILogging) => this.addToLogger(data));

    this.toggleView = new FormGroup({
      view: new FormControl('', null),
      viewTitles: new FormControl(false, null),
      viewRowTitles: new FormControl(false, null),
      viewPoster: new FormControl(false, null),
      viewShapes: new FormControl(false, null),
      viewText: new FormControl(false, null),
      viewTable: new FormControl(false, null),
      viewPointTitle: new FormControl(false, null),
      viewCinema: new FormControl(false, null)
    }, null);
    this.loggerBox = document.getElementById('logger');
  }

  // public getPath(): void {
  //     let path: any;
  //     path.toPathData();
  // }

  public openSchemeData(): void {
    this.openSchemeInfo = true;
    if ( ! this.schemeInfo ) {
        this.schemeInfo = this._parserService.getSchemeData();
        let rowCounts = 0, cellCounts = 0, sectorFans = 0;
        const sectorCounts = this.schemeInfo.length;
        this.schemeInfo.forEach((el: ISchemeInfo) => {
          if ( el.sectorRow ) {
              rowCounts += el.sectorRow;
              cellCounts += el.sectorCell;
          } else {
              sectorFans += 1;
          }
        });
        this.schemeFullInfo = [sectorCounts, rowCounts, cellCounts, sectorFans];
    }
  }

  public changeLang(code: string): void {
    this.lang = code;
  }

  public svgFileLoad(event: any): void {
    if (event.target.files[0]) {

      if (this.fileLoadAccess) {
        this.fileLoadAccess = false;
        this.csvLoadAccess = false;
        this.showLoader = false;
        this.logEvent = [];
        this.countLogError = 0;
        this.svgHtmlRes = null;
      }

      this.addToLogger({msg: 'Загрузили Svg', error: null});
      this.svgFileRead(event.target);
      this.svgLoadInput.nativeElement.value = null;
      this.svgLoadInput.nativeElement.files = null;
    }
  }

  public svgFileRead(input: any): void {
    this.showLoader = true;
    let file: File = input.files[0];
    let svgReader: FileReader = new FileReader();
    svgReader.readAsDataURL(file);
    svgReader.onloadend = () => {
      this.svgHtmlRes = this._sanitizer.bypassSecurityTrustHtml(Base64.decode(svgReader.result.replace('data:image/svg+xml;base64,', '')));
      setTimeout(() => {
        this.addToLogger({msg: 'Получили Svg, начали парсить', error: null});
        this.scheme = this._parserService.getParsedSvg();
        this.createJsonFile(JSON.stringify(this.scheme), 'stage.json');
        if ( this.scheme.sectors.length ) {
            this._createLangNav(this.scheme.sectors[0].multiName);
        }
        this.hasRowTables = ParserHelperService.checkRowTable(this.scheme.rows);
      });
    };
  }

  private _createLangNav(obj: IMultiLang): void {
      Object.keys(obj).forEach((code: string) => {
        const name = code === 'uk' ? 'UA' : code.toUpperCase();
        this.langNav.push({name, code});
      });
  }

  public createDefs(strs: string[]): void {
    if ( strs.length ) {
      strs.forEach((str: string) => {
          this.svgDefs.nativeElement.insertAdjacentHTML('beforeend', str);
      });
    }
  }

  public createJsonFile(text: string, name: string, mimeType: string = 'application/json'): void {


    let file = new Blob([text], {type: mimeType});
    this.fileLink = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
    this.fileName = name;
    this.fileLoadAccess = true;
    this.toggleView.get('view').setValue('row');
    this.toggleView.get('view').updateValueAndValidity();
    this.showLoader = false;
      setTimeout(() => this.createDefs(this.scheme.defs), 1000);
  }

  public addToLogger(data: ILogging): void {
    // if ( data.error || data.error === null  ) {
        this.logEvent.unshift(data);
    // }
    if (data.error) {
      this.countLogError++;
    }
  }

}
